@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;700&family=Space+Mono:wght@400;700&display=swap');

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  --bg-color: #f2eae4;
  --bg-transparent: #f2eae4c9;
  --blue: #0063f2;
  --lite-blue: #8addff;
  --orange: #ff8d19;
  --black: #161616;
  --text: #333333;
  --small: small;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Space Mono', monospace;
}
