.header {
  position: fixed;
  width: 100vw;
  background-color: #eee8e2ab;
  z-index: 100;
}

.header__container {
  display: flex;
  padding: 2rem 6vw;
  padding-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
}

.heaeder__icon {
  height: 2.6rem;
  margin-right: 2rem;
}

.disconnect__button {
  height: 1.3rem;
  cursor: pointer;
}

.header__button {
  cursor: pointer;
  border: 1px solid var(--blue);
  background-color: transparent;
  color: var(--blue);
  font-family: 'Space Mono', monospace;
  font-size: var(--small);
  padding: 0.3rem 4rem;
  border-radius: 1rem;
}

.header__button__dark {
  cursor: pointer;
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--bg-color);
  font-family: 'Space Mono', monospace;
  font-size: var(--small);
  padding: 0.3rem 2rem;
  border-radius: 1rem;
}

.header__nav {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header__link {
  color: var(--blue);
  text-decoration: none;
  margin-left: 2rem;
  font-family: 'Space Mono', monospace;
}

.header__link__active {
  font-weight: 600;
}

.body {
  background-color: var(--bg-color);
  height: 100vh;
  width: 100vw;
  position: relative;
  top: 0;
  background-image: url(./bg1.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% auto;
  margin: 0;
  padding: 0;
}

.body__container {
  max-width: 600px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.body__logo {
  height: 3rem;
  margin: 0 auto;
  display: block;
  margin-bottom: 1.2rem;
}

.body__wrap {
  width: 100%;
  padding: 2.8rem 2.8rem;
  padding-bottom: 3rem;
  background-color: #f8f5f3b6;
  border-radius: 0.8rem;
  margin-bottom: 1.6rem;
}

.body__title {
  color: var(--text);
  font-weight: 300;
  text-align: center;
}

.body__subtitle {
  color: var(--blue);
  font-family: 'Space Mono', monospace;
  font-weight: 300;
  font-size: 12pt;
  margin-bottom: 0;
}

.input__container {
  margin: 0 auto;
  width: 85%;
  margin-top: 3.6rem;
  display: flex;
}

.body__input {
  display: block;
  width: 100%;
  font-size: var(--small);
  color: var(--text);
  border: 0;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 0.5rem 1rem;
  border-radius: 0.6rem 0 0 0.6rem;
}

.input__max {
  font-size: var(--small);
  color: var(--blue);
  border: 0;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 0.5rem 1rem;
  border-radius: 0 0.6rem 0.6rem 0;
  cursor: pointer;
}

.body__button {
  cursor: pointer;
  font-family: 'Space Mono', monospace;
  font-size: var(--small);
  border: 1px solid var(--blue);
  background-color: transparent;
  color: var(--blue);
  padding: 0.3rem 4rem;
  width: 85%;
  border-radius: 1rem;
  display: block;
  margin: 0 auto;
  text-align: center;
  text-decoration: none;
}

.body__button__completed {
  cursor: pointer;
  font-family: 'Space Mono', monospace;
  font-size: var(--small);
  border: 0;
  background-color: var(--blue);
  color: white;
  padding: 0.3rem 4rem;
  width: 85%;
  border-radius: 1rem;
  display: block;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 1rem;
  text-align: center;
  text-decoration: none;
}

/* maiusc button letter spacing */
span {
  letter-spacing: 0.08em;
}

.body__loader {
  width: 150px;
  display: block;
  margin: 0 auto;
}

.body__loader__txt {
  text-align: center;
  font-size: large;
  font-weight: 300;
  margin-top: 0;
  color: var(--blue);
}

.body__result__txt {
  text-align: center;
  font-size: var(--small);
  font-weight: 300;
  margin-top: 3rem;
}

.body__balance__txt {
  text-align: center;
  font-size: var(--small);
  font-weight: 300;
  margin-top: 1.8rem;
  margin-bottom: 0;
}

.body__balance__txt span {
  letter-spacing: normal;
  color: var(--orange);
  font-size: medium;
  font-weight: 400;
}

.body__login__txt {
  text-align: center;
  font-size: 11pt;
  font-weight: 300;
  margin-top: 1.2rem;
  margin-bottom: 0;
}

.body__login__txt a {
  color: var(--blue);
}

.body__delegation__percent {
  font-family: 'Space Mono', monospace;
  color: var(--orange);
  font-size: 72pt;
  font-weight: 400;
  margin: 0;
  text-align: center;
}

.delegation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delegation__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.delegation__amount__container {
  display: flex;
  align-items: center;
}

.delegation__amount {
  font-size: small;
  opacity: 0.8;
  margin-left: 1rem;
  font-family: 'Space Mono', monospace;
}

.delegation__txt {
  font-family: 'Space Mono', monospace;
  color: var(--text);
  font-size: var(--small);
  font-weight: 600;
}

.delegation__button {
  text-decoration: none;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  color: var(--blue);
  border: 1px solid var(--blue);
  font-family: 'Space Mono', monospace;
  font-size: var(--small);
  padding: 0.2rem 2.3rem !important;
  border-radius: 1rem;
  height: fit-content;
}

/* HIDE DEFAULT INPUT STYLING */
input:focus {
  outline: none;
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

/* STYLE INPUIT TYPE RANGE */
#delegate {
  display: block;
  background: linear-gradient(
    to right,
    var(--blue) 0%,
    var(--blue) 50%,
    #aac6ed 50%,
    #aac6ed 100%
  );
  border-radius: 3px;
  height: 3px;
  width: 80%;
  margin: 0 auto;
  outline: none;
  -webkit-appearance: none;
}

#delegate::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--blue);
  cursor: pointer;
}

#delegate::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--blue);
  cursor: pointer;
}
