.footer {
  padding: 1.3rem 6vw;
  background-color: #f8f5f38c;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__logo {
  height: 1.6rem;
}

.footer__twitter {
  height: 1.2rem;
  padding-left: 4rem;
}

.footer__container p {
  font-size: 8pt;
  font-weight: 300;
}

.footer_text {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.footer__status {
  background-color: #32d74b;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 0.6rem;
  box-shadow: 1px 1px 15px 1px #32d74b;
}

.disconnected {
  background-color: #e01837;
  box-shadow: 1px 1px 15px 1px #e01837;
}
