.mobile {
  background-color: var(--blue);
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile__logo__container {
  position: absolute;
  top: 0;
  width: 100vw;
}
.mobile__logo {
  height: 3rem;
  display: block;
  margin: 1rem auto;
}

.mobile__container {
  width: 90%;
}

.mobile__title {
  color: white;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}

.mobile__txt {
  color: white;
  text-align: center;
  max-width: 400px;
  width: 90%;
  margin: 1rem auto;
}

.mobile__button {
  cursor: pointer;
  font-family: 'Space Mono', monospace;
  font-size: medium;
  border: 0;
  background-color: white;
  color: var(--blue);
  padding: 0.3rem 1rem;
  width: 85%;
  border-radius: 1rem;
  display: block;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 1rem;
  text-align: center;
  text-decoration: none;
  max-width: 400px;
  letter-spacing: 0.1em;
}
